<template>
  <BAlert show variant="info" v-bind="$attrs" v-on="$listeners">
    <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>

    <template #default>
      <BIconExclamationCircle class="mr-2" />
      <slot />
    </template>
  </BAlert>
</template>

<script>
import { BAlert, BIconExclamationCircle } from 'bootstrap-vue'

export default {
  name: 'InfoAlert',
  components: {
    BAlert,
    BIconExclamationCircle,
  },
}
</script>

<style scoped></style>
