import px from 'vue-types'

let validateType
import('vue-types').then(module => {
  validateType = module.validateType
})

export const pxNullable = propType =>
  px.custom(function nullable(value) {
    return value === null || validateType?.(propType, value)
  })

export const pxAgentSlot = () => px.oneOf(['Ability1', 'Ability2', 'Grenade', 'Passive', 'Ultimate'])
export const pxDamageType = () => px.oneOf(['Ability', 'Bomb', 'Melee', 'Weapon'])
export const pxEventType = () => px.oneOf(['utility', 'defuse', 'plant', 'kill', 'position', 'death'])
export const pxEco = () => px.oneOf(['P', '$', '$$', '$$$', '$$$$'])
export const pxId = () => px.string
export const pxGid = () => px.string
export const pxGrid = () => px.oneOf(['Red', 'Blue'])
export const pxPlantSite = () => px.oneOf(['', 'A', 'B', 'C'])
export const pxRole = () => px.oneOf(['atk', 'def'])
export const pxRoleLowercase = () => px.oneOf(['atk', 'def'])
export const pxRoundOutcome = () => px.oneOf(['Elimination', 'Detonate', 'Defuse', 'Time'])
export const pxVodStatus = () => px.oneOf(['provisioning', 'executing', 'succeeded', 'partial', 'failed'])
export const pxVodPlatform = () => px.oneOf(['twitch', 'youtube'])
export const pxHalf = () => px.oneOf(['first', 'second', 'overtime'])

export const pxWeapon = () =>
  px.shape({
    display_icon_url: px.string,
    id: pxId().isRequired,
    kill_stream_icon_url: px.string.isRequired,
    name: px.string.isRequired,
  })

export const pxAbility = () =>
  px.shape({
    slot: pxAgentSlot().isRequired,
    description: px.string,
    displayName: px.string.isRequired,
    displayIcon: pxNullable(px.string),
  }).loose

export const pxAgent = () =>
  px.shape({
    id: pxId().isRequired,
    name: px.string.isRequired,
    display_icon_small_url: px.string.isRequired,
    abilities: px.objectOf(pxAbility()).isRequired,
  }).loose

export const pxLocation = () =>
  px.shape({
    x: px.number.isRequired,
    y: px.number.isRequired,
  }).loose

export const pxEvent = () =>
  px.shape({
    id: pxId().isRequired,
    gid: pxGid().isRequired,
    type: pxEventType().isRequired,
    match_id: pxId().isRequired,
    round_id: pxId().isRequired,
    round_time_millis: px.integer.isRequired,
    location: pxLocation().isRequired,
    round_team_id: pxId().isRequired,
    match_player_id: pxId().isRequired,
    round_player_id: pxNullable(pxId()),
  }).loose

export const pxSmoke = () =>
  px.shape({
    id: pxId().isRequired,
    gid: pxGid().isRequired,
    type: px.oneOf(['smoke']),
    match_id: pxId().isRequired,
    round_id: pxId().isRequired,
    round_time_millis: px.integer.isRequired,
    location: pxLocation().isRequired,
    radius: px.number.isRequired,
  })

export const pxWall = () =>
  px.shape({
    id: pxId().isRequired,
    gid: pxGid().isRequired,
    type: px.oneOf(['wall']),
    match_id: pxId().isRequired,
    round_id: pxId().isRequired,
    round_time_millis: px.integer.isRequired,
    location: pxLocation().isRequired,
    round_team_id: pxId().isRequired,
    vector: px.arrayOf(pxLocation()).isRequired,
    match_player_id: pxNullable(pxId()),
  })

const pxKillPlayer = () =>
  px.shape({
    match_player_id: pxId().isRequired,
    round_player_id: pxNullable(pxId()),
    round_team_id: pxId().isRequired,
    location: pxLocation().isRequired,
  })

export const pxRoundData = () =>
  px.shape({
    id: pxId().isRequired,
    match_id: pxId().isRequired,
    round_num: px.integer.isRequired,
    round_duration_millis: px.integer.isRequired,
    plant_site: pxNullable(pxPlantSite()),
    plant: pxNullable(pxEvent()),
    defuse: pxNullable(pxEvent()),
    round_teams: px.arrayOf(pxId()).isRequired,
    outcome: pxNullable(pxRoundOutcome()),
    vod_replay_url: pxNullable(px.string),
    vod_start_millis: pxNullable(px.number),
    vod_duration_millis: px.number,
  })

export const pxRoundTeam = () =>
  px.shape({
    id: pxId().isRequired,
    team_id: pxId().isRequired,
    match_id: pxId().isRequired,
    round_id: pxId().isRequired,
    grid: pxGrid().isRequired,
    role: pxRole().isRequired,
    eco: pxNullable(pxEco()),
    win: pxNullable(px.bool),
  })

export const pxFinishingDamage = () =>
  px.shape({
    damage_item: pxId().isRequired,
    damage_type: pxDamageType().isRequired,
  }).loose

// TODO: extend pxEvent
export const pxKillEvent = () =>
  px.shape({
    id: pxId().isRequired,
    gid: pxGid().isRequired,
    rgid: pxGid().isRequired,
    type: pxEventType().isRequired,
    match_id: pxId().isRequired,
    round_id: pxId().isRequired,
    round_time_millis: px.integer.isRequired,
    location: pxLocation().isRequired,
    round_team_id: pxId().isRequired,
    match_player_id: pxId().isRequired,
    round_player_id: pxNullable(pxId()),
    post_mortem: px.bool.def(false),
    killer: pxKillPlayer().isRequired,
    victim: pxKillPlayer().isRequired,
    finishing_damage: pxFinishingDamage().isRequired,
  })

export const pxMatchPlayer = () =>
  px.shape({
    id: pxId().isRequired,
    agent_id: pxId().isRequired,
    player_id: pxNullable(pxId()),
    match_id: pxId().isRequired,
    team_id: pxId().isRequired,
    max_ultimate_orbs: pxNullable(px.number),
    team_composition_id: pxId().isRequired,
  })

export const pxOrbsPlayer = () =>
  px.shape({
    id: pxId().isRequired,
    agentId: pxId().isRequired,
    playerId: pxId().isRequired,
    teamId: pxId().isRequired,
    maxUltimateOrbs: px.number.isRequired,
    matchPlayerIds: px.arrayOf(pxId()).isRequired,
    matchIds: px.arrayOf(pxId()).isRequired,
  })

export const pxRoundPlayer = () =>
  px.shape({
    id: pxId().isRequired,
    round_id: pxId().isRequired,
    match_player_id: pxId().isRequired,
    round_team_id: pxId().isRequired,
    ultimate_orbs: pxNullable(px.number),
  })

export const pxTeam = () =>
  px.shape({
    id: pxId().isRequired,
    name: px.string.isRequired,
    players: px.arrayOf(pxId()).isRequired,
    image: px.string,
  })

export const pxPlayerData = () =>
  px.shape({
    id: pxId(),
    name: px.string.isRequired,
    is_active: px.bool.def(false),
    team_id: pxId().isRequired,
  })

export const pxMatch = () =>
  px.shape({
    id: pxId().isRequired,
    vod_id: pxNullable(pxId()),
    vod_status: pxNullable(pxVodStatus()),
    vod_platform: pxNullable(pxVodPlatform()),
    red_team_id: pxId(),
    blue_team_id: pxId(),
  })

export const pxEvents = () => px.arrayOf(pxEvent())

export const pxMap = () =>
  px.shape({
    id: pxId().isRequired,
    name: px.string.isRequired,
    display_icon_url: px.string,
    list_view_icon_url: px.string,
    url: px.string,
    rotate_deg: px.number,
  }).loose

export const pxToolData = () =>
  px.shape({
    agents: px.objectOf(pxAgent()).isRequired,
    firstRoundKills: px.objectOf(
      px.shape({
        round_time_millis: px.integer.isRequired,
        kill_id: pxId().isRequired,
      })
    ).isRequired,
    map: pxMap().isRequired,
    matchPlayers: px.objectOf(pxMatchPlayer()).isRequired,
    orbsPlayers: pxNullable(px.objectOf(pxOrbsPlayer())),
    players: px.objectOf(pxPlayerData()).isRequired,
    roundPlayers: px.objectOf(pxRoundPlayer()).isRequired,
    rounds: px.objectOf(pxRoundData()).isRequired,
    roundTeams: px.objectOf(pxRoundTeam()).isRequired,
    usedDamages: pxSelectedIds().isRequired,
    weapons: px.objectOf(pxWeapon()).isRequired,
  }).loose

export const pxSelectedIds = () => px.objectOf(px.bool.def(false))

export const pxSelectedData = () =>
  px.shape({
    agents: pxNullable(pxSelectedIds()),
    damage: pxNullable(pxSelectedIds()),
    eco: px.arrayOf(pxEco()).def([]),
    ecoOpponents: pxNullable(px.arrayOf(pxEco())),
    kills: pxNullable(
      px.shape({
        kills: px.bool,
        deaths: px.bool,
        onlyFirst: px.bool,
      })
    ),
    orbs: px.objectOf(pxSelectedIds()),
    outcome: pxNullable(pxRoundOutcome()),
    players: pxNullable(pxSelectedIds()),
    positions: pxNullable(px.oneOf(['first', 'pre', 'post', 'on'])),
    rounds: pxNullable(pxSelectedIds()),
    site: pxNullable(pxPlantSite()),
    team: pxNullable(pxId()),
    teamCompositions: pxNullable(px.objectOf(px.arrayOf(px.bool))),
    teamRole: pxNullable(pxRole()),
    time: pxNullable(px.shape({ start_time_millis: px.integer.isRequired, end_time_millis: px.integer.isRequired })),
    utilities: pxNullable(pxSelectedIds()),
    win: pxNullable(px.bool),
    half: pxNullable(pxHalf()),
    zones: pxNullable(pxSelectedIds()),
  })
