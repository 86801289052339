<template>
  <div>
    <PageTitle>Help</PageTitle>
    <div class="filter-container">
      <div class="filter">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text text-white-50 bg-primary">
              <BIconSearch />
            </span>
          </div>
          <input type="search" class="form-control" placeholder="Filter videos" v-model="filter" />
        </div>
      </div>
    </div>
    <div v-if="filteredVideos.length" class="card-deck">
      <div v-for="(video, idx) in filteredVideos" :key="idx" class="card bg-primary" :title="video.title">
        <div v-if="video.type === 'youtube'" class="card-img-top embed-responsive embed-responsive-16by9">
          <YoutubeReplay :id="video.id" :autoplay="false" :player-vars="playerVars" />
        </div>
        <img v-else-if="video.type === 'image'" class="card-img-top" :src="video.url" alt="video.title" />
        <div class="card-body">
          <h5 class="card-title">{{ video.title }}</h5>
          <p v-if="video.text" class="card-text">{{ video.text }}</p>
        </div>
      </div>
    </div>
    <div v-else>
      <InfoAlert>No matching videos!</InfoAlert>
    </div>
  </div>
</template>

<script>
import { BIconSearch } from 'bootstrap-vue'
import elasticlunr from 'elasticlunr'

import simulatedReplay from '../assets/help/2d_map_single.gif'
import mapDownloadImage from '../assets/help/dl_image.gif'
import expandableTeamTable from '../assets/help/dropdown.gif'
import ultimateOrbs from '../assets/help/Screen_Recording_14-03-2022_at_22.54.gif'
import timelineSelect from '../assets/help/timeline.gif'
import InfoAlert from '../components/generic/InfoAlert.vue'
import PageTitle from '../components/generic/PageTitle.vue'
import YoutubeReplay from '../components/replay/YoutubeReplay.vue'

const VIDEOS = Object.freeze([
  {
    type: 'image',
    url: ultimateOrbs,
    tags: ['orbs', 'ultimate', 'map', 'filter'],
    title: 'This is where to find and use the ultimate orb filters',
    text: 'We also added the option to select rounds by ultimate orb status - so you can select if a specific player is 1-2 orbs away from his ultimate or already has it at the beginning of the round.',
  },
  {
    type: 'image',
    url: expandableTeamTable,
    tags: ['team', 'stats', 'table', 'filter'],
    title: 'This is where to find and use the ultimate orb filters',
    text: 'We also added the option to select rounds by ultimate orb status - so you can select if a specific player is 1-2 orbs away from his ultimate or already has it at the beginning of the round.',
  },
  {
    type: 'image',
    url: simulatedReplay,
    tags: ['map', '2d replay'],
    title: 'Replay without VOD',
    text: 'We have added single round views when there are no vods available. You can just click on the timeline to go through each event.',
  },
  { type: 'youtube', id: 'dytyQE3NswU', title: 'Spectator view mode', tags: ['map', 'replay', 'spectator'] },
  {
    type: 'image',
    url: timelineSelect,
    tags: ['map', '2d replay'],
    title: 'Timeline select',
    text: 'You can now also select from the timeline events that happened through out the match. For example you can select the first 10 seconds from an entire match or several matches to see the start positions of a team.',
  },
  {
    type: 'image',
    url: mapDownloadImage,
    tags: ['map', 'save', 'export', 'image'],
    title: 'Download current map view as svg image',
  },
  { type: 'youtube', id: 'vE7iiVh6PvE', title: '2D Replay tool Release', tags: ['map', '2d replay'] },
  { type: 'youtube', id: 'PO9Zis0OwVI', title: 'Multi-match analysis guide', tags: ['map', 'multimatch'] },
  { type: 'youtube', id: '2oef4xt2QiA', title: 'Map release Demo for Augment', tags: ['map'] },
])

export default {
  name: 'HelpPage',
  components: { InfoAlert, BIconSearch, YoutubeReplay, PageTitle },
  data: () => ({
    filter: '',
  }),
  created() {
    this.index = elasticlunr(function () {
      this.addField('title')
      this.addField('text')
      this.addField('tags')
      this.setRef('idx')
      this.saveDocument(false)
    })
    VIDEOS.forEach((video, idx) => {
      video.idx = idx
      this.index.addDoc(video)
    })
  },
  computed: {
    filteredVideos() {
      return this.filter
        ? this.index
            .search(this.filter, {
              expand: true,
            })
            .map(({ ref }) => VIDEOS[ref])
        : VIDEOS
    },
    playerVars() {
      return {
        iv_load_policy: 1,
      }
    },
    videos() {
      return VIDEOS
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-container {
  margin-bottom: 2em;
}

.card-deck {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30em, 1fr));
  gap: 2em 1.5em;
}
</style>
